import React from "react";
import SliderProgram from "../../components/PageComponents/SliderProgram";
// import { Link } from "react-router-dom";
const ProgramPage = () => {
    return (
        <>
        <SliderProgram />
            <div className="container py-3">
                <div className="row my-5">
                    <div className="col-md-3 my-3">
                        <a href="#domestic_asset">
                        <div className="program-card-box">                            
                            <p>01</p>
                            <strong>Domestic Assault</strong>
                            <p className="divider"></p>
                        </div>
                        </a>
                    </div>
                    <div className="col-md-3 my-3">
                        <a href="#weapons-offences">
                        <div className="program-card-box">                            
                            <p>02</p>
                            <strong>Weapons Offences</strong>
                            <p className="divider"></p>
                        </div>
                        </a>
                    </div>
                    <div className="col-md-3 my-3">
                        <a href="#drug-offences">
                        <div className="program-card-box">
                            <p>03</p>
                            <strong>Drug Offences</strong>
                            <p className="divider"></p>
                        </div>
                        </a>
                    </div>
                    <div className="col-md-3 my-3">
                        <a href="#bail-hearings">
                        <div className="program-card-box">
                            <p>04</p>
                            <strong>Bail Hearings</strong>
                            <p className="divider"></p>
                        </div>
                        </a>
                    </div>
                    <div className="col-md-3 my-3">
                        <a href="#property-crime">
                        <div className="program-card-box">
                            <p>05</p>
                            <strong>Property Crime</strong>
                            <p className="divider"></p>
                        </div>
                        </a>
                    </div>
                    <div className="col-md-3 my-3">
                        <a href="#criminal-harassment">
                        <div className="program-card-box">
                            <p>06</p>
                            <strong>Criminstrongl Harassment</strong>
                            <p className="divider"></p>
                        </div>
                        </a>
                    </div>
                    <div className="col-md-3 my-3">
                        <a href="#sexual-offences">
                        <div className="program-card-box">
                            <p>07</p>
                            <strong>Sexual Offences</strong>
                            <p className="divider"></p>
                        </div>
                        </a>
                    </div>
                    <div className="col-md-3 my-3">
                        <a href="#extradition">
                        <div className="program-card-box">
                            <p>08</p>
                            <strong>Extradition</strong>
                            <p className="divider"></p>
                        </div>
                        </a>
                    </div>
                </div>
                <div className="card-section">
                    <div className="row" id="domestic_asset">
                        <div className="col-md-6">
                            <img src="./image/program1.jpg" alt="n/a" className="img-fluid" />
                        </div>
                        <div className="col-md-6">
                            <h2>Domestic Assault</h2>
                            <h5>Ut labore et dolore magna aliqua</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <h5>Eiusmod tempor incididunt</h5>
                            <p>Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                        </div>
                    </div>
                    <div className="row" id="weapons-offences">
                        <div className="col-md-6">
                            <h2>Weapons Offences</h2>
                            <h5>Voluptate velit esse quis nostrud</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <h5>Ut labore et dolore magna aliqua</h5>
                            <p>Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                        </div>
                        <div className="col-md-6">
                            <img src="./image/program2.jpg" alt="n/a" className="img-fluid" />
                        </div>
                    </div>
                    <div className="row" id="drug-offences">
                        <div className="col-md-6">
                            <h2>Drug Offences</h2>
                        </div>
                        <div className="col-md-6">
                            <h5>Excepteur sint occaecat cupidatat non proident</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <h5>Ut labore et dolore magna aliqua</h5>
                            <p>Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                        </div>
                    </div>
                    <div className="row" id="bail-hearings">
                        <div className="col-md-6">
                            <h2>Bail Hearings</h2>
                        </div>
                        <div className="col-md-6">
                            <h5>Excepteur sint occaecat cupidatat non proident</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <h5>Ut labore et dolore magna aliqua</h5>
                            <p>Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                        </div>
                    </div>
                    <div className="row" id="property-crime">
                        <div className="col-md-6">
                            <h2>Property Crime</h2>
                        </div>
                        <div className="col-md-6">
                            <h5>Excepteur sint occaecat cupidatat non proident</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <h5>Ut labore et dolore magna aliqua</h5>
                            <p>Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                        </div>
                    </div>
                    <div className="row" id="criminal-harassment">
                        <div className="col-md-6">
                            <h2>Criminal Harassment</h2>
                        </div>
                        <div className="col-md-6">
                            <h5>Excepteur sint occaecat cupidatat non proident</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <h5>Ut labore et dolore magna aliqua</h5>
                            <p>Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                        </div>
                    </div>
                    <div className="row" id="sexual-offences">
                        <div className="col-md-6">
                            <h2>Sexual Offences</h2>
                        </div>
                        <div className="col-md-6">
                            <h5>Excepteur sint occaecat cupidatat non proident</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <h5>Ut labore et dolore magna aliqua</h5>
                            <p>Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                        </div>
                    </div>
                    <div className="row" id="extradition">
                        <div className="col-md-6">
                            <h2>Extradition</h2>
                        </div>
                        <div className="col-md-6">
                            <h5>Excepteur sint occaecat cupidatat non proident</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <h5>Ut labore et dolore magna aliqua</h5>
                            <p>Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgramPage;