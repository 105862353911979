import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMobile, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import 'animate.css';
// import axios from "axios";

interface HeaderProps {
    siteInfo: SiteInfo | null;
}
interface SiteInfo {
    siteTitle: string;
    title: string;
    description: string;
    favicon: string;
    logo: string;
    address: string;
    email: string;
    emailShortTest: string;
    phone: string;
    phoneShortTest: string;
    support_phone_no: string;
    supportPhoneShortTest: string;
    facebook: string;
    twitter: string;
    linkedin: string;
    instagram: string;
    pinterest: string;
    youtube: string;
}

const Header: React.FC<HeaderProps> = ({siteInfo}) => {  
    return (
        <>
            <div className="container header">
                <div className="topbar">
                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-lg-6 col-sm-2 col-4 align-self-center">
                            <div className="icon">
                                <Link to="/">
                                    <img src="https://file.adi-bd.org/logo.png" alt="n/a" className="img-fluid"/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-6 col-lg-6 col-sm-2 col-8 align-self-center text-align-right" style={{color: "#87AF35", textAlign: "right"}}>
                            <FontAwesomeIcon icon={faPhone} className="" style={{color: "#87AF35", fontSize: "15px"}}/> &nbsp;
                             {siteInfo?.phone} , {siteInfo?.support_phone_no}<br/>
                            <FontAwesomeIcon icon={faEnvelope}/>&nbsp;{siteInfo?.email}                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;