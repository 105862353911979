import React, { useEffect, useState } from "react";
import SliderGovernance from "../../components/PageComponents/SliderGovernance";
import axios from "axios";
import { Link } from "react-router-dom";

interface Section {
    title: string;
    content: string;
    widgets?: { title: string; description: string }[];
  }
  
  interface PageData {
    sections: Section[];
    [key: string]: any; // Add this if your `page` object has other dynamic properties
  }
  
  interface AboutComponentProps {
    pageData: PageData;
  }

  interface Data {
    id: number;
    title: string;
    thumb: string | null;
    description: string | null;
    file_url: string;
    meta_key: string | null;
    meta_description: string | null;
    status: number;
    sort_order: number;
    created_at: string | null;
    updated_at: string | null;
  }

  const AnnualReort: React.FC<AboutComponentProps> = ({ pageData }) => {

    const [annualReports, setAnnualReports] = useState<Data[]>([]);
    const [loading, setLoading] = useState(true);
    const fetchPageData = async () => {
        try {
        setLoading(true);
        const response = await axios.get("https://api.adi-bd.org/api/annual-report");
        if (response.status === 200 && Array.isArray(response.data.data.data)) {
            setAnnualReports(response.data.data.data);
        } else {
            console.error("Unexpected data format");
        }
        } catch (error) {
        console.error(error);
        } finally {
        setLoading(false);
        }
    };

    useEffect(() => {
        fetchPageData();
    }, []);


    return (
        <>
            <div className="about-page-area">
                <div className="container py-5">
                    <div className="block my-3">
                        <h4 className="display-5 fw-bold adi-text-primary text-center">{pageData?.title}</h4>
                        <div className="paragraph fs-4">
                            <p>{pageData?.content}</p>
                        </div>
                    </div>
                    <div className="row mx-auto">
                    {annualReports.slice().sort((a, b) => a.sort_order - b.sort_order).map((annualReport) => (
                        <div className="col-md-4 mx-auto my-4" key={annualReport.id}>
                            <div className="card p-3">
                                <div className="block1 mb-3 text-center" >
                                    {/* <object
                                        style={{ width: '100%',  overflow: 'hidden' }}
                                        className="pdf img-fluid"
                                        data={`https://file.adi-bd.org/${annualReport.file_url}`}
                                        type="application/pdf">
                                    </object>                                 */}
                                    <img src={`https://file.adi-bd.org/${annualReport.thumb}`} className="img-fluid rounded" alt="" />
                                </div>                            
                                <div className="text-center">
                                    <p className="lead fw-bold fs-2">{annualReport.title}</p>
                                    <p>{annualReport.description}</p>
                                    <a href={'https://file.adi-bd.org/' + annualReport.file_url} target="_blank" className="btn adi-btn-secondary" rel="noopener noreferrer">
                                        View PDF
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>

                    <div className="block my-3">
                        {/* <h4 className="display-4 adi-text-primary">{sectionData?.widgets?.[1]?.title}</h4>
                    {sectionData?.widgets?.map((widget, index) => (
                        <div key={index}>
                            <h3 className="display-4 adi-text-primary">{widget.title}</h3>
                            <p>{widget.description} sasdfasdfsdf</p>
                        </div>
                        ))} */}
                        {pageData.sections.length !== 0 ? (
                            pageData.sections.map((section, index) => (
                                    <div key={index}>
                                    <h2 className="display-4 adi-text-primary">{section.title}</h2>
                                    <p>{section.content}</p>
                                    {/* Render widgets if they exist */}
                                    {/* {section.widgets && (
                                        <div>
                                        {section.widgets.map((widget, widgetIndex) => (
                                            <div key={widgetIndex}>
                                            <h3>{widget.title}</h3>
                                            <p>{widget.description}</p>
                                            </div>
                                        ))}
                                        </div>
                                    )} */}
                                    </div>
                                ))
                                ) : (
                                <div>
                                    
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnnualReort;