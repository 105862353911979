import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'animate.css';
// import './styles.css';
// style 


// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import axios from "axios";
import 'animate.css';
// import './relative/path/to/styles.css';


interface Data {
    id: number;
    title: string;
    subtitle: string | null;
    thumb: string | null;
    link_url: string;
    description: string | null;
    status: string | null;
    created_at: string | null;
    updated_at: string | null;
  }
const SliderHome = () => {
    const [SliderData, setSliderData] = useState<Data[]>([]);
    const [loading, setLoading] = useState(true);
    const fetchSliderData = async () => {
        try {
        setLoading(true);
        const response = await axios.get("https://api.adi-bd.org/api/slider");
        if (response.status === 200 && Array.isArray(response.data.data.data)) {
            setSliderData(response.data.data.data);
            console.log("__data__", SliderData);
        } else {
            console.error("Unexpected data format");
        }
        } catch (error) {
        console.error(error);
        } finally {
        setLoading(false);
        }
    };

    useEffect(() => {
        fetchSliderData();
    }, []);

    return (
        <>
            <div className="home-slider">
                <div className="position-relative">
                    <div className="banner-overlay">
                        <img src="./image/slider-bg.png" alt="" className="img-fluid overlay-image" />
                    </div>
                    <Swiper
                        speed={600}
                        // parallax={true}
                        effect={'fade'}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: true,
                        }}
                        navigation={true}
                        modules={[EffectFade, Pagination, Navigation, Autoplay]}
                        className="mySwiper"
                    >
                        <div
                        slot="container-start"
                        className="parallax-bg"
                        style={{
                            backgroundImage: 'image/1.webp',
                        }}
                        data-swiper-parallax="-23%"
                        ></div>
                        {SliderData.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <img src={'https://file.adi-bd.org/' + slide.thumb} alt={slide.title} className="img-fluid w-100" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>

        </>
    );
};

export default SliderHome;


