import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
import SliderHome from "../../components/PageComponents/SliderHome";
import { Link } from "react-router-dom";

const HomePage = () => {    
    return (
    <>
        <div className="py-0">                
            <SliderHome/>
        </div>
        <div className=" m-0 p-0">
            <div className="container" style={{textAlign:'justify'}}>
                <section className="row about-page-area animate__animated animate__fadeInUp animate__duration-2s">
                    <div className="col-lg-10 col-md-12 col-sm-11 mx-auto">
                        <div className="row py-5">
                            <div className="col-12">
                                <h3 className="display-5 fw-bold my-3 adi-text-primary">About Us</h3>
                                {/* <div className="strong fw-bold">---</div>  */}
                                <div className="p my-3 display-7"> <p className="display-7 fs-4">
                                    Alternative Development Initiative (ADI) is a non-profit development organization that began in 1993 with the sole interest and development efforts of a few like-minded people with extensive expertise in the country’s development sectors. The primary goal of ADI is to involve the rural poor in development activities that will help them gain capacity and develop their socioeconomic and moral values. The organization believes in the integrated development method, which includes participatory management and activity implementation based on people’s perceived needs.
                                    <br/><br/>
                                    Since its inception in April 1993, the organization has long been a target of rural poverty-stroke households, assisting them financially as well as engaging them in development activities to change their capacity in an effort to bring positive change in their economic and social state. We grow together through active participatory management and an active need-based plan.</p>
                                </div>
                                <div className="p my-4">
                                    <Link to="/about" className="btn adi-btn-secondary fs-4">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row section-page-area animate__animated animate__fadeInUp animate__duration-2s">
                    <div className="col-lg-10 col-md-12 col-sm-11 mx-auto">
                        <div className="row pt-5">
                            <div className="col-12">
                                {/* <p className="fw-bold">Alternative Development Initiative (ADI)</p> */}
                                {/* <h3 className="display-2 my-3 adi-text-primary">Goals & Objectives</h3> */}
                                <h3 className="display-5 fw-bold my-3 adi-text-primary">Goals & Objectives</h3>
                                {/* <div className="strong fw-bold">---</div>  */}
                                <div className="paragraph my-3 fs-4">
                                    Bangladesh is one of the world’s most densely populated developing countries. The vast majority of people in this country live in villages. The majority of these rural people live in abject poverty. The organization’s main goals are to alleviate their poverty by involving them in various development projects and to provide them with monetary and technical assistance collectively in order to improve their standard of living.
                                    <br/><br/>
                                    
                                    The overall goal of ADI is to bring livelihood security to poorer households by involving them in development activities. The specific objectives of ADI are:
                                    <br/><br/>
                                    <ul>
                                        <li>To develop rural women’s institutions for restoring and protecting their interest that would help establish their rights and realize their role in the society.</li>
                                        <li>To help rural communities to improve their capacity to avail local resources and available services.</li>
                                        <li>help rural poor communities to develop entrepreneurs.</li>
                                        <li>o generate community awareness about environmental degradation and assist them to conservation of natural resources.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row about-page-area animate__animated animate__fadeInUp animate__duration-2s">
                    <div className="col-lg-10 col-md-12 col-sm-11 mx-auto pt-5">
                        <div className="section-header text-center">
                            <h3 className="display-5 fw-bold adi-text-primary">Microfinance</h3>
                            <span>&mdash;</span>
                        </div>
                        <div className="row p-sm-5">
                            <div className="col-md-8 col-lg-8 col-sm-12">
                                <Link to="/page/jagoron-rural-microcredit">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/adi-fisheries.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Jagoron (Rural Microcredit)
                                        </h4>
                                        <div className="fs-4">
                                        Jagoron (Rural Microcredit) is a specialized microfinance program designed to provide small-scale financial assistance to rural entrepreneurs, particularly those engaged in agriculture, small businesses, and cottage industries. By offering collateral-free loans.activities and improve their socio-economic conditions.
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 py-sm-0 py-4">
                                <Link to="/page/agrosor-micro-enterprise-loans">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/agriculture.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Agrosor (Micro-enterprise Loans)
                                        </h4>
                                        <div className="fs-4">
                                        Agrosor is a specialized microfinance program designed to support small and medium-sized enterprises (SMEs) by providing financial assistance to entrepreneurs. This loan program helps businesses scale up their operations, increase productivity.
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 py-4">
                                <Link to="/page/buniad-ultra-poor-loans">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/education.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Buniad (Ultra-poor Loans)
                                        </h4>
                                        <div className="fs-4">
                                        Buniad is a specialized microfinance program designed to uplift ultra-poor individuals by providing them with small loans to start or expand income-generating activities.
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 py-4">
                                <Link to="/page/sufolon-loan-seasonal-loans">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/microcredit.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Sufolon Loan (Seasonal Loans)
                                        </h4>
                                        <div className="fs-4">
                                        Sufolon Loan is a specialized microfinance product designed to support farmers and seasonal entrepreneurs by providing short-term credit tailored to their specific agricultural and business cycles.
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 py-4">
                                <Link to="/page/enrich">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/jagoron.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Enrich
                                        </h4>
                                        <div className="fs-4">
                                        Enrich is a comprehensive rural development program designed to empower communities through an integrated approach to poverty alleviation and sustainable growth.
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row about-page-area animate__animated animate__fadeInUp  animate__duration-2s">
                    <div className="col-lg-10 col-md-12 col-sm-11 mx-auto pt-3">
                        <div className="section-header text-center">
                            <h2 className="adi-text-primary display-5 fw-bold">Activities</h2>
                            <p className="lead fs-4">The overall goal of ADI is to bring livelihood security to poorer households <br/>by involving them in development ctivities.</p>
                        </div>
                        <div className="row p-sm-5">
                            <div className="col-md-6 col-lg-6 col-sm-12 my-2 mx-auto">
                                <Link to="/page/agriculture-project">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/adi-fisheries.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Agriculture Project
                                        </h4>
                                        <div className="fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 my-2 mx-auto">
                                <Link to="/page/wash-projects">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/agriculture.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            WASH Projects
                                        </h4>
                                        <div className="fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 my-3 mx-auto">
                                <Link to="/page/fisheries-and-livestock-project">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/education.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Fisheries & Livestock Project
                                        </h4>
                                        <div className="fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 my-3 mx-auto">
                                <Link to="/page/elderly-people-probin-program">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/microcredit.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Elderly People (Probin) Program
                                        </h4>
                                        <div className="fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 my-3 mx-auto">
                                <Link to="/page/health-program">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/jagoron.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Health program
                                        </h4>
                                        <div className="fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 my-3 mx-auto">
                                <Link to="/page/educational-program">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/jagoron.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Educational program
                                        </h4>
                                        <div className="fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 my-3 mx-auto">
                                <Link to="/page/youth-program">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <img src="image/jagoron.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <h4 className="card-title fs-2 pt-2">
                                            Youth program
                                        </h4>
                                        <div className="fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row section-page-area animate__animated animate__fadeInUp  animate__duration-2s">
                    <div className="col-lg-10 col-md-12 col-sm-11 mx-auto pt-3 pb-5">
                        <div className="section-header text-center">
                            <h2 className="display-5 fw-bold adi-text-primary">News</h2>
                            <p className="lead fs-4">Bangladesh is one of the world's most densely<br/>
                            populated developing countries..</p>
                        </div>
                        <div className="row p-sm-5 ">
                            <div className="col-md-4 col-lg-4 col-sm-12 my-2 ">
                                <Link to="#0">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <img src="image/adi-fisheries.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <div className="pt-2 fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 my-2 ">
                                <Link to="#0">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <img src="image/agriculture.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <div className="pt-2 fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 my-2 ">
                                <Link to="#0">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <img src="image/education.webp" alt="" className="img img-responsive w-100 rounded"/>
                                        <div className="pt-2 fs-4">
                                            As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>                                
                        </div>
                        <div className="text-center">
                            <Link to="/program" className="btn adi-btn-secondary fs-4">View All</Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </>
    );
};

export default HomePage;