import React from "react";
import {Routes, Route } from "react-router-dom";

import HomePage from "../pages/Public/HomePage";
import ProgramPage from "../pages/Public/ProgramPage";
import PublicationPage from "../pages/Public/PublicationPage";
import GovernancePage from "../pages/Public/GovernancePage";
import ContactPage from "../pages/Public/ContactPage";
import ArchivePage from "../pages/Public/ArchivePage";
import DefaultPage from "../pages/Public/DefaultPage";
import AnnualReort from "../pages/Public/AnnualReport";
import GalleryPage from "../pages/Public/GalleryPage";


const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/page/:slug" element={<DefaultPage />} />
            <Route path="/annual-report" element={<AnnualReort pageData={{ sections: [] }}  />} />
            <Route path="/program" element={<ProgramPage />} />
            <Route path="/publication" element={<PublicationPage />} />                
            <Route path="/governance" element={<GovernancePage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/archive" element={<ArchivePage />} />
        </Routes>
    );
};

export default PublicRoutes;