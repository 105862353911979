import React, { useEffect, useState } from "react";
import axios from "axios";
interface Data {
    id: number;
    type: string;
    title: string;
    description: string | null;
    file_url: string;
    meta_key: string | null;
    meta_description: string | null;
    status: number;
    created_at: string;
    updated_at: string;
  }
  const GalleryPage: React.FC = () => {
    const [galleryData, setGalleryData] = useState<Data[] | null>(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('IMAGE');
    const fetchGalleryData = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://api.adi-bd.org/api/gallery');
        if (response.status === 200) {
          setGalleryData(response.data.data.data);
          console.log('__galleryData__', galleryData);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    
    useEffect(() => {
      fetchGalleryData();
    }, []);
    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
      };
    return (
        <>
            <div className="about-page-area">
                <div className="container py-5">
                    <div className="block my-3">
                        <h4 className="display-4 adi-text-primary"></h4>
                        <div className="paragraph">
                            <p></p>
                        </div>
                    </div>
                    <div className="block my-3" style={{minHeight: "80vh"}}>
                    <div>
                        <nav>
                            <div className="nav nav-tabs d-flex justify-content-center border-0" id="nav-tab" role="tablist">
                            
                            <a
                                className={`nav-item nav-link btn me-4 w-25 ${activeTab === "IMAGE" ? "adi-btn-primary" : "adi-btn-secondary"}`}
                                onClick={() => handleTabChange("IMAGE")}
                            >
                                Image
                            </a>
                            <a
                                className={`nav-item nav-link btn me-4 w-25 ${activeTab === "YOUTUBE" ? "adi-btn-primary" : "adi-btn-secondary"}`}
                                onClick={() => handleTabChange("YOUTUBE")}
                            >
                                Video
                            </a>
                            </div>
                        </nav>

                        <div className="tab-content mt-5" id="nav-tabContent">
                            {/* Video Tab */}
                            <div className={`tab-pane fade ${activeTab === "VIDEO" ? "show active" : ""}`}>
                            {galleryData &&
                                Array.isArray(galleryData) &&
                                galleryData.filter((item) => item.type === "VIDEO").map((item, index) => (
                                <div key={index}>
                                    <video width="320" height="240" controls >
                                    <source src={item.file_url} type="video/mp4" className="rounded" />
                                    Your browser does not support the video tag.
                                    </video>
                                </div>
                                ))}
                            </div>

                            {/* Image Tab */}
                            <div className={`tab-pane fade ${activeTab === "IMAGE" ? "show active" : ""}`}>
                                <div className="row">
                                    {galleryData &&
                                    Array.isArray(galleryData) &&
                                    galleryData.filter((item) => item.type === "IMAGE").map((item, index) => (
                                    <div key={index} className="col-md-4 col-6 py-3">
                                        <img src={ 'http://file.adi-bd.org/' + item.file_url} alt={item.title} className="img img-fluid rounded"/>
                                    </div>
                                    ))}
                                </div>
                            
                            </div>

                            {/* YouTube Tab */}
                            <div className={`tab-pane fade ${activeTab === "YOUTUBE" ? "show active" : ""}`}>
                           
                                <div className="embed-responsive embed-responsive-16by9">
                                    <div className="row">
                                    {galleryData &&
                                        Array.isArray(galleryData) &&
                                        galleryData.filter((item) => item.type === "YOUTUBE").map((item, index) => (
                                            <div className="col-md-6 col-sm-12" key={index}>
                                                <iframe
                                                width="100%"
                                                height="315"
                                                src={item.file_url}
                                                title={item.title}
                                                frameBorder="0"
                                                allowFullScreen
                                                className="rounded"
                                                />
                                                <p className="fw-bold h4 py-2">{item.title}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default GalleryPage;